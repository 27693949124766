.pdfViewer.pdfViewer .spread {
	margin: 1px 0 !important;
	display: flex;
	justify-content: center;
}

.pdfViewer.pdfViewer .page {
	margin: 0 !important;
	border: none;
	background-color: transparent;
	transform: translateZ(0);
	backface-visibility: hidden;
	-webkit-font-smoothing: subpixel-antialiased;
	position: relative;
}

.pdfViewer.pdfViewer.removePageBorders .page {
	margin: 0 !important;
	border: none;
}

.pdfViewer.pdfViewer .spread .page,
.pdfViewer.pdfViewer.scrollHorizontal .page,
.pdfViewer.pdfViewer.scrollWrapped .page {
	margin-inline: 0 !important;
}

.pdfViewer.pdfViewer:is(.scrollHorizontal, .scrollWrapped) .spread {
	margin-inline: 0 !important;
}

/* Ensure pages touch each other in spread mode */
.pdfViewer.pdfViewer .spread .page + .page {
	margin-left: -1px !important;
}

/* Add gradient effects for spread pages using pseudo-elements */
.pdfViewer.pdfViewer .spread .page:first-child::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 30%;
	height: 100%;
	pointer-events: none;
	background: linear-gradient(
		to right,
		transparent,
		rgba(0, 0, 0, 0.06),
		rgba(0, 0, 0, 0.2)
	);
}

.pdfViewer.pdfViewer .spread .page + .page::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 30%;
	height: 100%;
	pointer-events: none;
	background: linear-gradient(
		to left,
		transparent,
		rgba(0, 0, 0, 0.06),
		rgba(0, 0, 0, 0.17)
	);
}

/* PDF.js viewer container */
.pdfViewer.pdfViewer {
	background-color: transparent;
	position: relative;
	width: 100%;
	height: 100%;
}

/* Text layer styles */
.pdfViewer.pdfViewer .textLayer {
	opacity: 1;
	mix-blend-mode: multiply;
	transform-origin: 0 0;
}

.pdfViewer.pdfViewer .textLayer span {
	color: transparent;
	position: absolute;
	white-space: pre;
	cursor: text;
	transform-origin: 0% 0%;
}

.pdfViewer.pdfViewer .textLayer .highlight {
	margin: -1px;
	padding: 1px;
	background-color: rgba(180, 0, 170, 0.2);
	border-radius: 4px;
}

.pdfViewer.pdfViewer .textLayer .highlight.selected {
	background-color: rgba(0, 100, 0, 0.2);
}

/* Drawing toolbar styles */
.drawing-toolbar {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 4px 8px;
	background: var(--background-color);
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.drawing-toolbar .toolbar-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	padding: 4px;
	border: none;
	border-radius: 4px;
	background: transparent;
	color: var(--text-color);
	cursor: pointer;
	transition: background-color 0.2s;
}

.drawing-toolbar .toolbar-button:hover {
	background: var(--hover-color);
}

.drawing-toolbar .toolbar-button.active {
	background: var(--primary-color);
	color: white;
}

.drawing-toolbar input[type='color'] {
	width: 32px;
	height: 32px;
	padding: 0;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.drawing-toolbar .slider-container {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.drawing-toolbar .slider-container label {
	font-size: 12px;
	color: var(--text-color);
}

.drawing-toolbar input[type='range'] {
	width: 100px;
}

/* PDF.js ink editor styles */
.annotationEditorLayer .inkEditor {
	--ink-color: #000000;
	--ink-thickness: 1px;
	--ink-opacity: 100%;
}

.annotationEditorLayer .inkEditor .inkEditorCanvas {
	opacity: var(--ink-opacity);
}

.annotationEditorLayer .inkEditor path {
	fill: none;
	stroke: var(--ink-color);
	stroke-width: var(--ink-thickness);
	stroke-linecap: round;
	stroke-linejoin: round;
}

#dice-canvas {
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 60;
	width: 100%;
	height: 100%;
	border: 1px solid black;
	background-color: transparent;
	pointer-events: none;
}

#staticdice-canvas {
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 60;
	width: 200px;
	height: 200px;
	border: 1px solid black;
	background-color: transparent;
	pointer-events: none;
}

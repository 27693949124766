.progressWindowContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

.ProgressWindow {
  margin: auto;
  background: white;
  padding: 20px;
  min-width: 260px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0,0,0,.2);
}

.title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}

.progressTrack {
  background: #eee;
  border-radius: 100px;
  overflow: hidden;
  background: linear-gradient(180deg, #DBDBDB 0%, #F2F2F2 100%);
  /* margin-bottom: 20px; */
}

.progressBar {
  height: 20px;
  /* border-radius: 100px; */
  background: black;
  transition: width 0.5s;
}

.button {
  margin-left: -20px;
  margin-right: -20px;
  padding-top: 20px;
  text-align: center;
  border-top: 1px solid #eee;
}
.DropToUploadMessage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: rgba(0,0,0,.4);
}

.Message {
  margin: auto;
  background: white;
  padding: 20px;
  min-width: 260px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0,0,0,.2);
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://rsms.me/inter/inter.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
}

html {
	font-family: 'Inter', sans-serif;
	font-weight: 400;
}

html,
body {
	overscroll-behavior: none;
}

body {
	background: black;
	min-height: 100vh;
}

body.game {
	background: black url('assets/backgrounds/desktop-wood.jpg') fixed;
	overflow: hidden;
}

body.dropzone {
	padding: 0;
	border: none;
}

body.dragging * {
	user-select: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

[data-radix-popper-content-wrapper] {
	z-index: 9999 !important;
}

@keyframes shine {
	0% {
		background-position: 200% 0;
	}
	100% {
		background-position: -200% 0;
	}
}
